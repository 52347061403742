<template>
  <div class="main-account-manager">
    <b-card title="Quản lý ca làm việc">
      <b-row>
        <b-col lg="4">
          <div class="search-account-input">
            <b-form-input
                v-model="workShiftSettingSearch.keyWord"
                type="text"
                placeholder="Nhập nhóm, tên ca, ... để tìm kiếm"
                @keyup.enter="getListAllAccount"
            />
          </div>
        </b-col>
        <b-col lg="4">
          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              @click="getListAllAccount"
          >
            <feather-icon
                icon="SearchIcon"
                class="mr-50"
            />
            <span class="align-middle">Tìm kiếm</span>
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <div class="table-school-block">
            <b-row>
              <b-col
                  lg="12"
                  style="margin: auto"
              >
                <div class="acction-add-account mb-2">
                  <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      @click="getFormAddNewEmployee"
                  >
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Thêm mới</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <span style="color: green">Có tổng số {{ totalCountList }} bản ghi</span>
            <b-table
                responsive
                :items="listWorkShift"
                :per-page="perrPage"
                :current-page="currentPage"
                :fields="fields"
            >
              <template #cell(index)="data">
                <span>{{ data.index + 1 }}</span>
              </template>
              <template #cell(hanhdong)="data">
                <b-button
                    v-b-tooltip.hover.top="'Cập nhật'"
                    variant="flat-primary"
                    class="btn-icon"
                    @click="updateAccount(data.item)"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
                <b-button
                    v-b-tooltip.hover.top="'Xóa'"
                    variant="flat-danger"
                    class="btn-icon"
                    @click="deleteDepartment(data.item)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </template>
            </b-table>
            <template>
              <b-pagination
                  v-model="currentPage"
                  class="float-right"
                  :per-page="perrPage"
                  hide-goto-end-buttons
                  :total-rows="rows"
              />
            </template>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, VBTooltip, BFormInput, BButton, BTable, BPagination,
} from 'bootstrap-vue'

import { required, max } from '@validations'

import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import utils from '../../views/manage-work-shift-branch/utils'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      timeSpans: [],
      workShiftSettingSearch: {
        pageIndex: null,
        pageSize: null,
        keyWord: null,
      },
      listWorkShift: [],
      currentPage: 1,
      perrPage: 10,
      required,
      max,
      totalRecordsList: 0,
      totalCountList: null,
      fields: [
        {
          key: 'index', label: 'Id', thClass: 'custom-th-index',
        },
        {
          key: 'groupName', label: 'Nhóm',
        },
        {
          key: 'name', label: 'Tên ca',
        },
        {
          key: 'startHour', label: 'Bắt đầu',
        },
        {
          key: 'endHour', label: 'Kết thúc',
        },
        {
          key: 'bufferTime', label: 'Thời gian linh động',
        },
        {
          key: 'hanhdong', label: 'Hành động', thClass: 'custom-th-action-account', tdClass: 'custom-td-action-account',
        },
      ],
      listPerPage: [
        3, 5, 10,
      ],
    }
  },
  computed: {
    rows () {
      if (this.listWorkShift) {
        return this.listWorkShift.length
      }
    },
  },
  created() {
    const timeDuration = 30
    const timeSpans = []
    for (let i = 0; i < 24 * 60; i += timeDuration) {
      timeSpans.push({ value: i, label: utils.minuteToHour(i) })
    }
    this.timeSpans = timeSpans
    this.getListAllAccount()
  },
  methods: {
    getListAllAccount() {
      this.listWorkShift = []
      this.$crm.post('work-shift/organization/find-by-condition', this.workShiftSettingSearch).then(response => {
        let workShiftInfo = response.data.listData
        workShiftInfo.map(item => {
          // item.organizationBranchId = this.selectedOrganizationBranchValue
          item.startHour = this.timeSpans.find(timeSpan => timeSpan.value === item.startHour).label
          item.endHour = this.timeSpans.find(timeSpan => timeSpan.value === item.endHour).label
        })
        workShiftInfo.sort((item1, item2) => {
          if (item1.groupName < item2.groupName) return -1
          if (item1.groupName > item2.groupName) return 1
          return 0
        })
        this.listWorkShift = workShiftInfo
        this.totalCountList = this.listWorkShift.length
      })
    },
    getFormAddNewEmployee() {
      this.$router.push('/add-work-shift')
    },
    updateAccount(item) {
      this.$router.push({
        name: 'update-work-shift',
        query: {
          id: item.id,
        },
      })
    },
    deleteDepartment(item) {
      this.$swal({
        title: 'Xóa ca làm việc',
        text: `Bạn có muốn xóa ca làm việc ${item.name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy bỏ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$crm.post(`work-shift/organization/delete/${item.id}`).then(res => {
            this.getListAllAccount()
            this.$swal({
              icon: 'success',
              title: 'Xóa ca làm việc!',
              text: 'Xóa ca làm việc thành công',
              showConfirmButton: false,
              timer: 1500,
            })
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '../../@core/scss/custom/account';
@import "~ag-grid-community/dist/styles/ag-grid.scss";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>
